import styled from 'styled-components';
import { rem } from 'polished';

export const PlayerWrapper = styled.div`
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  max-width: 100%;
  height: 100%;
  width: ${rem(900)};
`;
