import React from 'react';

import { Container } from 'components/common';
import { AccordionSection } from 'components/CMS';

import Accordions from './accordions';

const FAQ = () => {
  return (
    <Container>
      <AccordionSection
        headline="Fees FAQs"
        accordions={Accordions}
        initState={true}
      />
    </Container>
  );
};

export default FAQ;
