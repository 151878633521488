import React from 'react';
import Player from 'react-player';
import PropTypes from 'prop-types';
import { Modal } from '@strikelabs/luna';

import { PlayerWrapper } from './style';

const VideoPlayer = ({ url, options, isOpen, handleClose, ...rest }) => {
  const playerStyle = {
    border: '0',
    height: '100%',
    left: '0',
    position: 'absolute',
    top: '0',
    width: '100',
  };

  return (
    <Modal
      pad={false}
      closeIcon={false}
      isOpen={isOpen}
      onClose={handleClose}
      iconColor="backgroundDark"
    >
      <PlayerWrapper>
        <Player
          style={playerStyle}
          config={{ youtube: { ...options } }}
          playing
          playsInline
          controls
          url={url}
          {...rest}
          width="100%"
          height="100%"
        />
      </PlayerWrapper>
    </Modal>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  options: PropTypes.shape({}),
  isOpen: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
};

export default VideoPlayer;
