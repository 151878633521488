import React from 'react';
import { Box } from '@strikelabs/luna';

export default [
  {
    label: 'What frees can I expect when selling my house?',
    content: (
      <Box mb={2}>
        At Strike, we’ll sell your home for free. Other estate agents will
        charge you a flat rate upfront or a percentage of the total sale, which
        can cost you thousands. You’ll also have to think about conveyancing
        fees and moving costs. Check out our cost of{' '}
        <a
          style={{ color: '#000000' }}
          href="/selling/guides/how-to-sell-my-house-online"
        >
          selling my home guide
        </a>{' '}
        to learn more.
      </Box>
    ),
    startOpen: true,
  },
  {
    label: 'How much tax do you pay when selling a house?',
    content: (
      <Box mb={2}>
        Normally you don’t have to pay any. Stamp Duty is paid by the buyer and,
        as long as it’s your primary residence, you won’t have to pay any
        capital gains tax when you sell your home. If it’s a second home or
        buy-to-let property, you’ll want to check with HMRC.
      </Box>
    ),
    startOpen: true,
  },
  {
    label: 'What do I need to sell my house?',
    content: (
      <Box mb={2}>
        That depends on how you’re selling it. Normally you’ll need floor plans,
        photos, and an Energy Performance Certificate. Then, you’ll want to list
        your home on major property sites — but we’ll take care of all that for
        you.
      </Box>
    ),
    startOpen: true,
  },
  {
    label: 'What is the quickest way to sell my house?',
    content: (
      <Box mb={2}>
        That depends. Some people like the idea of selling through an auction
        for a quick sale, but you can end up paying a lot in auctioneer fees —
        and not getting a great price. We’ve found that an accurate valuation
        and great marketing are the best way to achieve the right price as
        quickly as possible.
      </Box>
    ),
    startOpen: true,
  },
  {
    label: 'What adds the most value to a house?',
    content: (
      <Box mb={2}>
        Normally space. More is more when it comes to a home — so adding
        extensions or additions can often add significant property value. But if
        you don’t want to invest a lot, a fresh coat of neutral paint or adding
        an extra wardrobe can help make your home more desirable, without a huge
        investment. Our property expert can talk you through any changes you
        might want to make to get the most out of your home.
      </Box>
    ),
    startOpen: true,
  },
  {
    label: 'Why is selling with an online agent different?',
    content: (
      <Box mb={2}>
        There are a lot of ways online estate agents are different from
        traditional ones — but not every online estate agent is the same. The
        basic difference is online estate agents don’t have brick-and-mortar
        locations, but use technology and their expertise to sell homes more
        efficiently.
        <br />
        <br />
        But they’re all different. Some online estate agents will still charge
        hefty fees, but we’ll sell your home for free. At Strike, we put you in
        control. We’re here to sell your home on your terms. For free. That’s
        what sets us apart.
      </Box>
    ),
    startOpen: true,
  },
  {
    label: 'How can Strike really be free?',
    content: (
      <Box mb={2}>
        Does it sound too good to be true? It’s not. Strike really does sell
        your home for free. Firstly, we don’t have to pay expensive overheads
        because we don’t have any high street offices. That makes a huge
        difference.
        <br />
        <br />
        But the Strike model is different from other estate agents in that we
        only make money through our partners or our optional extras. If you
        choose to use one of them for mortgage advice or moving services, we get
        a fee — and we’ll always be upfront about that. If you don’t want to use
        them, that’s OK. We’re still happy to sell your home for free. We just
        want you to be happy with how you’re selling your home, whatever that
        looks like for you.
        <br />
        <br />
        Want to learn more? Don’t hesitate to ask. We’re always happy to share.
      </Box>
    ),
    startOpen: true,
  },
  {
    label: 'How much do you make from 3rd party referrals?',
    content: (
      <Box mb={2}>
        That depends. Whenever we point you toward one of our partners,
        we&apos;ll let you know. When they give you a quote, our referral will
        be included — so you always know exactly where your money is going.
      </Box>
    ),
    startOpen: true,
  },
  {
    label: 'How long will my advert be online for?',
    content: (
      <Box mb={2}>
        As long as you need it to be — some of our properties sell within days,
        some take a little longer. Normally we keep a listing live for 6 months,
        but we&apos;re happy to extend. You can always have a chat with us so we
        know what you&apos;re looking for from your sale and what timeline
        you&apos;d like to achieve.
      </Box>
    ),
    startOpen: true,
  },
  {
    label:
      'Do I have to use one of your recommended partners in order to sell for free?',
    content: (
      <Box mb={2}>
        Never. Our recommend partners are just that — recommended. If you
        don&apos;t want to use them, that&apos;s not a problem. We&apos;ll still
        sell your home for free.
      </Box>
    ),
    startOpen: true,
  },
  {
    label: 'What is free and what costs extra?',
    content: (
      <Box mb={2}>
        Great question. We can offer you everything you need to sell your home
        for free &mdash; things like a valuation, a property listing, even
        negotiating your sale, and being by your side right through until moving
        day. Optional extras, like marketing boosts or hosted viewings, cost
        more &mdash; but only if you choose to use them. We&apos;re here to help
        you sell your home, however works best for you.
      </Box>
    ),
    startOpen: true,
  },
];
