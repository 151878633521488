import React from 'react';

const MortgageAdvice = () => (
  <svg
    width="49"
    height="72"
    viewBox="0 0 49 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.16914"
      y="1.16914"
      width="45.8027"
      height="69.1855"
      rx="5.70815"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <rect
      x="10.7974"
      y="9.42182"
      width="26.5463"
      height="14.1672"
      rx="1.58178"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <circle cx="13.7256" cy="37.8946" r="3.90098" fill="#2D7650" />
    <circle cx="13.7256" cy="48.2972" r="3.90098" fill="#2D7650" />
    <circle cx="13.7256" cy="59.5668" r="3.90098" fill="#2D7650" />
    <circle cx="24.1283" cy="37.8946" r="3.90098" fill="#2D7650" />
    <circle cx="24.1283" cy="48.2972" r="3.90098" fill="#2D7650" />
    <circle cx="24.1283" cy="59.5668" r="3.90098" fill="#2D7650" />
    <circle cx="34.5309" cy="37.8946" r="3.90098" fill="#2D7650" />
    <circle cx="34.5309" cy="48.2972" r="3.90098" fill="#2D7650" />
    <circle cx="34.5309" cy="59.5668" r="3.90098" fill="#2D7650" />
  </svg>
);

export default MortgageAdvice;
