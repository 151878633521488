import styled from 'styled-components';
import { Box, Flex } from '@strikelabs/luna';

import { Container } from 'components/common';

export const Outer = styled.div`
  background: ${({ theme }) => theme.colors.backgroundDark};
  padding-top: ${({ theme }) => theme.spacing.xl};
  padding-bottom: ${({ theme }) => theme.spacing.xl};
  ${({ theme }) => theme.media.desktop} {
    padding-top: ${({ theme }) => theme.spacing.xxl};
    padding-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

export const Inner = styled(Container)`
  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
  }
`;

export const ExtrasContainer = styled(Flex)`
  border-radius: 7px;
`;

export const PriceContainer = styled(Box)`
  border: solid 1px #2d7650;
`;

export const KlarnaLogo = styled.img`
  width: 80px;
  transform: translate(10px, 10px);
`;
