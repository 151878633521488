import styled from 'styled-components';
import { rem } from 'polished';

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  padding: ${({ theme }) => theme.spacing.rg};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${({ theme }) => theme.media.smallTablet} {
    width: calc(50% - 24px);
    padding: ${({ theme }) => theme.spacing.md};
    margin: ${({ theme }) => `0 ${theme.spacing.sm} ${theme.spacing.md} 0`};
  }

  ${({ theme }) => theme.media.desktop} {
    margin-right: ${rem(30)};
    width: calc(33% - 18px);
    padding: ${({ theme }) => theme.spacing.lg};
  }
`;

export const ColumnText = styled.h2`
  max-width: ${rem(335)};
  font-weight: 600;
  font-size: ${rem(36)};
  line-height: ${rem(42)};

  ${({ theme }) => theme.media.smallTablet} {
    font-size: ${rem(32)};
    line-height: ${rem(40)};
    letter-spacing: ${rem(-0.2)};
  }
`;
