import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { identifier } from '@strikelabs/pax';
import { motion } from 'framer-motion';

import Play from 'components/Icons/Play';
import VideoPlayer from 'components/VideoPlayer';
import Image from 'components/Image';
import {
  Outer,
  Inner,
  TextContainer,
  ImageContainer,
  Title,
  Subtitle,
  PlayButton,
} from './style';

const Hero = ({
  title,
  subtitle,
  image,
  backgroundPosition,
  showPlay,
  imageAlt,
  videoURL,
}) => {
  const [isVideoOpen, toggleVideo] = useState(false);

  return (
    <Outer>
      <Inner>
        <TextContainer image={image}>
          {title && (
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { delay: 0.5, duration: 1.25 },
              }}
            >
              <Title {...identifier({ name: 'title', requireId: false })}>
                {title}
              </Title>
            </motion.div>
          )}
          {subtitle && (
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { delay: 0.75, duration: 1 },
              }}
            >
              <Subtitle
                mt={[2, 2, 2, 2, 3]}
                {...identifier({ name: 'subtitle', requireId: false })}
              >
                {subtitle}
              </Subtitle>
            </motion.div>
          )}
        </TextContainer>
        {image && (
          <ImageContainer>
            <motion.div
              initial={{ y: -10, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { delay: 0.25, duration: 1.5 },
              }}
            >
              <Image
                src={image}
                alt={imageAlt}
                layout="fullWidth"
                objectPosition={backgroundPosition}
                loading="eager"
              />
            </motion.div>
            {showPlay && (
              <PlayButton onClick={() => toggleVideo(true)}>
                <Play width="30px" height="30px" />
              </PlayButton>
            )}
          </ImageContainer>
        )}
        <VideoPlayer
          isOpen={isVideoOpen}
          handleClose={() => toggleVideo(false)}
          url={videoURL}
        />
      </Inner>
    </Outer>
  );
};

Hero.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  image: PropTypes.string,
  backgroundPosition: PropTypes.string,
  showPlay: PropTypes.bool,
  imageAlt: PropTypes.string,
  videoURL: PropTypes.string,
};

Hero.defaultProps = {
  title: null,
  subtitle: null,
  image: '',
  backgroundPosition: '50% 50%',
  showPlay: false,
  imageAlt: '',
  videoURL: 'https://www.youtube.com/watch?v=3eKm_N_vsTg',
};

export default Hero;
