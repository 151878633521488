import React from 'react';
import { Text, Flex, Box } from '@strikelabs/luna';

import {
  MortgageAdvice,
  MarketingBoosts,
  HostedViewings,
  MovingServices,
} from './Icons';
import Link from 'components/Link';
import {
  Outer,
  Inner,
  ExtrasContainer,
  PriceContainer,
  KlarnaLogo,
} from './style';

const HowCanWeSellForFree = () => {
  return (
    <Outer>
      <Inner>
        <Text as="h2" color="white" ext mb={[3, 3, 3, 3, 5]}>
          How can we sell for free?
          <br /> Glad you asked.
        </Text>
        <Text as="p" ext color="white" mb={5}>
          We&apos;ve waved goodbye to pointlessly high fees &mdash; we make
          money through optional extras along the way, but you never have to use
          them.
          <br />
          It&apos;s your sale, it&apos;s your choice.
        </Text>

        <Flex
          flexDirection={['column', 'column', 'column', 'column', 'row']}
          flexWrap={'wrap'}
          mb={4}
          justifyContent="space-between"
        >
          <ExtrasContainer
            flexDirection="column"
            width={[1, 1, 1, 1, 4.85 / 10]}
            p={4}
            backgroundColor="backgroundLight"
            mb={4}
            minHeight="401px"
          >
            <Flex
              mb={2}
              alignItems={[
                'flex-start',
                'flex-start',
                'flex-start',
                'flex-start',
                'center',
              ]}
              flexDirection={['column', 'column', 'column', 'column', 'row']}
            >
              <Box mr={2} mb={[2, 2, 2, 2, '0']}>
                <MortgageAdvice />
              </Box>
              <Text as="h3" ext>
                Mortgage advice
              </Text>
            </Flex>

            <Text styleType="body" ext>
              We pride ourselves on offering fast, straightforward mortgage
              advice &mdash; without the jargon. We have access to over 12,000
              mortgage deals to make sure you&apos;re getting the best possible
              rate.
            </Text>
          </ExtrasContainer>
          <ExtrasContainer
            flexDirection="column"
            width={[1, 1, 1, 1, 4.85 / 10]}
            p={4}
            mb={4}
            backgroundColor="backgroundLight"
            minHeight="401px"
          >
            <Flex
              mb={2}
              alignItems={[
                'flex-start',
                'flex-start',
                'flex-start',
                'flex-start',
                'center',
              ]}
              flexDirection={['column', 'column', 'column', 'column', 'row']}
            >
              <Box mr={2} mb={[2, 2, 2, 2, '0']}>
                <MarketingBoosts />
              </Box>
              <Text as="h3" ext>
                Marketing boosts
              </Text>
            </Flex>
            <Text styleType="body" ext>
              Our Performance Package includes professional photos, a video
              trailer, and a premium listing on Rightmove.
              <br />
              <br />
              *&pound;899 in some London postcodes
            </Text>
            <PriceContainer
              py={1}
              px={2}
              width="fit-content"
              alignSelf="flex-end"
              mt={[3, 3, 3, 3, 'auto']}
            >
              <Text as="h3" color="#2D7650">
                from &pound;699*
              </Text>
            </PriceContainer>
            <Text styleType="body" ext mt={2} ml="auto">
              or in 3 monthly instalments
            </Text>
          </ExtrasContainer>
          <ExtrasContainer
            flexDirection="column"
            width={[1, 1, 1, 1, 4.85 / 10]}
            p={4}
            backgroundColor="backgroundLight"
            mb={[4, 4, 4, 4, '0']}
            minHeight="401px"
          >
            <Flex
              mb={2}
              alignItems={[
                'flex-start',
                'flex-start',
                'flex-start',
                'flex-start',
                'center',
              ]}
              flexDirection={['column', 'column', 'column', 'column', 'row']}
            >
              <Box mr={2} mb={[2, 2, 2, 2, '0']}>
                <HostedViewings />
              </Box>
              <Text as="h3" ext>
                Hosted viewings package
              </Text>
            </Flex>
            <Text styleType="body" ext>
              Don&apos;t want to host viewings yourself? Going to be out of
              town? That&apos;s OK. We know that life can get in the way, so we
              offer a Hosted Viewings Package. With a Strike agent there to
              host, you never miss a potential buyer.
              <br />
              <br />
              *&pound;999 in some London postcodes
            </Text>
            <PriceContainer
              py={1}
              px={2}
              width="fit-content"
              alignSelf="flex-end"
              mt={[3, 3, 3, 3, 'auto']}
            >
              <Text as="h3" color="#2D7650">
                from &pound;799*
              </Text>
            </PriceContainer>
            <Text styleType="body" ext mt={2} ml="auto">
              or in 3 monthly instalments
            </Text>
          </ExtrasContainer>
          <ExtrasContainer
            flexDirection="column"
            width={[1, 1, 1, 1, 4.85 / 10]}
            p={4}
            backgroundColor="backgroundLight"
            minHeight="401px"
          >
            <Flex
              mb={2}
              alignItems={[
                'flex-start',
                'flex-start',
                'flex-start',
                'flex-start',
                'center',
              ]}
              flexDirection={['column', 'column', 'column', 'column', 'row']}
            >
              <Box mr={2} mb={[2, 2, 2, 2, '0']}>
                <MovingServices />
              </Box>
              <Text as="h3" ext>
                Moving services
              </Text>
            </Flex>
            <Text styleType="body" ext>
              We also offer free moving services to take care of all of those
              little details &mdash; from surveys to removals and everything in
              between. We get a referral fee from our providers, but the service
              is totally free to use.
            </Text>
          </ExtrasContainer>
        </Flex>
        <Text color="white" ext mb={4}>
          Split your purchases into 3 interest-free payments.{' '}
          <Link to="/">Learn more</Link>{' '}
          <KlarnaLogo src="https://docs.klarna.com/assets/media/d57b84c8-a93f-4a15-940e-aff9bd6ac208/compressed/Klarna_MarketingBadge_Pink_RGB.svg" />
        </Text>
        <Text color="white" ext>
          You never have to use any of our extra services and we&apos;ll always
          sell your home for free.
          <br />
          In fact, about 40% of our customers choose a totally free sale, while
          about 60% use some of our optional extras &mdash; it really is up to
          you.
        </Text>
      </Inner>
    </Outer>
  );
};

export default HowCanWeSellForFree;
