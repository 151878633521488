import styled from 'styled-components';
import { Text } from '@strikelabs/luna';
import { rem } from 'polished';

import { Container } from 'components/common';

export const Outer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.lg};

  ${({ theme }) => theme.media.desktop} {
    padding-bottom: ${({ theme }) => theme.spacing.xl};
  }
`;

export const Inner = styled(Container)`
  padding: 0;

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
    padding: ${({ theme }) => `0 ${theme.spacing.xl}`};
  }
`;

export const TextContainer = styled.div`
  margin-bottom: ${({ theme, image }) => image && theme.spacing.lg};
  padding: ${({ theme }) => `0 ${theme.defaults.gutterSpacing}`};

  ${({ theme }) => theme.media.tablet} {
    padding: ${({ theme }) => `0 ${theme.spacing.lg}`};
  }

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: ${({ theme, image }) => image && theme.spacing.xl};
    padding: 0;
  }
`;

export const Title = styled(Text).attrs({ as: 'h1', ext: true })`
  ${({ theme }) => theme.media.giant} {
    max-width: 70%;
  }
`;

export const Subtitle = styled(Text).attrs({ as: 'h3', ext: true })`
  ${({ theme }) => theme.media.giant} {
    max-width: 70%;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: auto;

  ${({ theme }) => theme.media.smallTablet} {
    height: ${rem(380)};
  }

  ${({ theme }) => theme.media.desktop} {
    height: ${rem(416)};
  }
`;

export const PlayButton = styled.div`
  cursor: pointer;
  width: ${rem(70)};
  height: ${rem(70)};
  border-radius: 100%;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.accent};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;
