import React from 'react';

const HostedViewings = () => (
  <svg
    width="84"
    height="62"
    viewBox="0 0 84 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3158 60.5277V32.7298H38.7649V20.6243H80.0134V60.5277H12.3158Z"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      d="M36.8262 32.6425L14.2901 31.9491C12.426 31.8917 11.158 30.0352 11.7828 28.2779L17.042 13.4865C17.4319 12.3898 18.4699 11.6572 19.6339 11.6572H49.2827C50.0123 11.6572 50.712 11.3673 51.2279 10.8514L60.073 2.00628C61.1709 0.90842 62.9592 0.936066 64.0226 2.06734L78.795 17.7826C80.4456 19.5386 79.2006 22.4176 76.7906 22.4176H42.4126C40.8933 22.4176 39.6617 23.6493 39.6617 25.1686V29.8929C39.6617 31.4453 38.3779 32.6903 36.8262 32.6425Z"
      fill="white"
    />
    <path
      d="M36.4624 32.7298H13.8359C11.9731 32.7298 10.6489 30.9171 11.2153 29.1425L16.1846 13.5717C16.5486 12.4313 17.6083 11.6572 18.8053 11.6572H48.386C49.1155 11.6572 49.8153 11.3673 50.3311 10.8515L59.1763 2.00628C60.2742 0.90842 62.0625 0.93607 63.1259 2.06735L80.0767 20.1001C80.902 20.9781 80.2795 22.4176 79.0745 22.4176H41.9642C40.4449 22.4176 39.2133 23.6493 39.2133 25.1686V29.9788C39.2133 31.4981 37.9817 32.7298 36.4624 32.7298Z"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <mask id="path-4-inside-1_30_165" fill="white">
      <rect
        x="20.8307"
        y="39.455"
        width="8.07035"
        height="11.6572"
        rx="1.37546"
      />
    </mask>
    <rect
      x="20.8307"
      y="39.455"
      width="8.07035"
      height="11.6572"
      rx="1.37546"
      fill="white"
      stroke="#2D7650"
      strokeWidth="4.67656"
      mask="url(#path-4-inside-1_30_165)"
    />
    <mask id="path-5-inside-2_30_165" fill="white">
      <rect
        x="47.7318"
        y="26.9009"
        width="8.07035"
        height="11.6572"
        rx="1.37546"
      />
    </mask>
    <rect
      x="47.7318"
      y="26.9009"
      width="8.07035"
      height="11.6572"
      rx="1.37546"
      fill="white"
      stroke="#2D7650"
      strokeWidth="4.67656"
      mask="url(#path-5-inside-2_30_165)"
    />
    <mask id="path-6-inside-3_30_165" fill="white">
      <rect
        x="63.8727"
        y="26.9009"
        width="8.07035"
        height="11.6572"
        rx="1.37546"
      />
    </mask>
    <rect
      x="63.8727"
      y="26.9009"
      width="8.07035"
      height="11.6572"
      rx="1.37546"
      fill="white"
      stroke="#2D7650"
      strokeWidth="4.67656"
      mask="url(#path-6-inside-3_30_165)"
    />
    <path
      d="M54.9054 60.0791V50.2153C54.9054 47.7392 56.9127 45.7318 59.3889 45.7318V45.7318C61.8651 45.7318 63.8725 47.7392 63.8725 50.2153V60.0791"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      d="M39.2131 24.2111L39.2132 60.5276"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      d="M1.99994 15.9802C6.03511 10.1072 19.0373 1.58856 31.1429 15.9802C26.177 22.5628 15.0022 31.6282 1.99994 15.9802Z"
      fill="white"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <circle
      cx="16.3472"
      cy="16.3266"
      r="4.37413"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
  </svg>
);

export default HostedViewings;
