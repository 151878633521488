import React from 'react';
import { Highlight, Flex, Text } from '@strikelabs/luna';
import PropTypes from 'prop-types';

import useInView from 'hooks/useInView';
import GreyPanel from 'components/GreyPanel';
import Button from 'components/common/Button';
import Link from 'components/Link';

import { Outer, ColumnContainer, Breakpoint } from './style';

const Explained = ({ noCta }) => {
  const { ref, isCenter } = useInView(true);

  return (
    <Outer ref={ref}>
      <Text as="h2" ext mb={null}>
        Let&apos;s get you moving
      </Text>
      <ColumnContainer>
        <GreyPanel number="1.">
          We <Highlight active={isCenter}>value</Highlight> your <Breakpoint />{' '}
          home and get you ready to sell
        </GreyPanel>
        <GreyPanel number="2.">
          We&apos;ll get you the <Highlight active={isCenter}>best</Highlight>{' '}
          possible offer
        </GreyPanel>
        <GreyPanel number="3.">
          We manage the sale to{' '}
          <Highlight active={isCenter}>completion</Highlight>
        </GreyPanel>
      </ColumnContainer>
      {!noCta && (
        <Flex justifyContent="center">
          <Link to="/selling/how-it-works">
            <Button styleType="blackVariant" fullWidth>
              Learn more
            </Button>
          </Link>
        </Flex>
      )}
    </Outer>
  );
};

Explained.propTypes = {
  noCta: PropTypes.bool,
};

Explained.defaultProps = {
  noCta: false,
};

export default Explained;
