import React from 'react';
import PropTypes from 'prop-types';

const Play = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect fill="none" height="32" width="32" />
      </g>
      <g>
        <polygon points="10,2.001 24,16 10,30  " />
      </g>
    </svg>
  );
};

Play.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Play.defaultProps = {
  width: '32px',
  height: '32px',
};

export default Play;
