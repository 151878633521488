import React from 'react';
import LazyLoad from 'react-lazyload';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Stats from 'components/Stats';
import Explained from 'components/Explained';
import Hero from 'components/Hero';
import SavingsSlider from 'components/SavingsSlider';
import BrandImg from 'images/strike-brand.png';
import { ByYourSide } from 'components/Pages/Homepage/Sections';
import { FAQ, HowCanWeSellForFree } from 'components/Pages/Fees/Sections';
import { Guides } from 'components/Pages/Homepage/Sections';

const Fees = () => (
  <Layout showFixedCta>
    <SEO
      title="Sell Your House For Free With Strike | Our Frees Explained"
      description="We Offer A Full Service Estate Agency For Free Because We Think It's Time To Change The Way Estate Agency Works, Forever. Go On Strike. Strike A Better Deal."
      canonical="/selling/fees"
      metaImage={BrandImg}
    />
    <Hero
      title="Our “frees” explained"
      subtitle="We offer a full service estate agency for free because we think it’s time to change the way that estate agency works, forever."
      image="fees-hero.png"
      imageAlt="Family craft at table"
    />
    <ByYourSide />
    <LazyLoad height={1574} offset={80}>
      <HowCanWeSellForFree />
    </LazyLoad>
    <LazyLoad height={732} offset={80}>
      <SavingsSlider title="See how much you could save when you sell your home for free" />
    </LazyLoad>
    <LazyLoad height={566} offset={80}>
      <Explained />
    </LazyLoad>
    <LazyLoad height={392} offset={80}>
      <Stats />
    </LazyLoad>
    <LazyLoad height={1313} offset={80}>
      <Guides />
    </LazyLoad>
    <FAQ />
  </Layout>
);

export default Fees;
