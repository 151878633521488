import styled from 'styled-components';

import { Container } from 'components/common';

export const Outer = styled(Container)`
  margin-bottom: 0;
`;

export const ColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => `${theme.spacing.md} 0`};
  justify-content: space-between;

  div:nth-child(3) {
    margin-right: 0;
  }

  ${({ theme }) => theme.media.desktop} {
    margin: ${({ theme }) => `${theme.spacing.xl} 0`};
  }
`;

export const Breakpoint = styled.br`
  display: none;

  ${({ theme }) => theme.media.giant} {
    display: block;
  }
`;
