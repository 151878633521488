import React from 'react';

const MarketingBoosts = () => (
  <svg
    width="55"
    height="77"
    viewBox="0 0 55 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="41.0606"
      height="70.1317"
      rx="4.12637"
      transform="matrix(-1 0 0 1 43.0605 4.76758)"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      d="M22.5304 65.7156C21.3712 65.7156 20.4354 66.6479 20.4354 67.8028C20.4354 68.9578 21.3712 69.8901 22.5304 69.8901C23.6896 69.8901 24.6253 68.9578 24.6253 67.8028C24.6253 66.6479 23.6896 65.7156 22.5304 65.7156"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      d="M42.5984 61.2248L2.88921 61.1233"
      stroke="#2D7650"
      strokeWidth="2.33828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.7145 35.6913V25.3998L22.2151 18.1521L13.4839 25.3998V35.6913H30.7145Z"
      stroke="#2D7650"
      strokeWidth="2.33828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.4577 34.5164V28.2825H19.556V34.5164"
      stroke="#2D7650"
      strokeWidth="2.33828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3128 44.0061H30.9101"
      stroke="#2D7650"
      strokeWidth="2.33828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3128 49.4345H30.9101"
      stroke="#2D7650"
      strokeWidth="2.33828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="43.0977"
      cy="12.3782"
      r="10.3782"
      fill="white"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.6542 7.30944L45.1898 10.1663L48.642 10.626C48.758 10.6406 48.8551 10.7142 48.8929 10.8161C48.9307 10.9181 48.9027 11.0309 48.8205 11.1076L46.3326 13.2968L46.9159 16.4382C46.9367 16.5438 46.8912 16.6512 46.7981 16.7159C46.705 16.7807 46.5803 16.7918 46.4754 16.7447L43.4042 15.2561L40.3092 16.7775C40.2043 16.8246 40.0795 16.8135 39.9865 16.7488C39.8934 16.684 39.8479 16.5766 39.8687 16.471L40.452 13.3296L37.9284 11.1076C37.8462 11.0309 37.8182 10.9181 37.856 10.8161C37.8938 10.7142 37.9909 10.6406 38.1069 10.626L41.5353 10.1663L43.0709 7.30944C43.1147 7.19594 43.2315 7.12012 43.3625 7.12012C43.4935 7.12012 43.6103 7.19594 43.6542 7.30944Z"
      fill="#2D7650"
    />
  </svg>
);

export default MarketingBoosts;
