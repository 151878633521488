import React from 'react';

const MovingServices = () => (
  <svg
    width="62"
    height="62"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.31023 23.6987V41.1956M4.31023 23.6987L2.6016 21.5019C1.70365 20.3474 1.86611 18.6921 2.97138 17.7342L20.4784 2.56144C21.5124 1.66533 23.0477 1.66533 24.0817 2.56144L41.5887 17.7342C42.694 18.6921 42.8564 20.3474 41.9585 21.5019L40.2499 23.6987M4.31023 23.6987L20.5081 10.0584C21.532 9.19614 23.028 9.19614 24.052 10.0584L40.2499 23.6987M9.98491 53.0179H7.06115C5.54186 53.0179 4.31023 51.7863 4.31023 50.267V41.1956M23.2258 53.0179H40.2499M53.4908 53.0179H57.3603C58.8796 53.0179 60.1112 51.7863 60.1112 50.267V42.0772C60.1112 41.5039 59.9321 40.9448 59.5988 40.4783L51.4763 29.1067C50.9599 28.3838 50.1262 27.9547 49.2378 27.9547H40.2499M40.2499 23.6987V38.4447C40.2499 39.964 39.0182 41.1956 37.4989 41.1956H4.31023"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      d="M46.3974 27.9548V38.9177C46.3974 40.437 47.6291 41.6686 49.1483 41.6686H60.5841"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <circle
      cx="16.6054"
      cy="54.4366"
      r="5.92421"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <circle
      cx="46.8703"
      cy="54.4366"
      r="5.92421"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <rect
      x="18.2474"
      y="22.9764"
      width="9.01108"
      height="9.01108"
      rx="1.58178"
      fill="white"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
  </svg>
);

export default MovingServices;
