import React from 'react';
import { Box, Text } from '@strikelabs/luna';
import PropTypes from 'prop-types';

import { Column, ColumnText } from './style';

const GreyPanel = ({ number, children, smallText }) => {
  return (
    <Column>
      <Box mb={4}>
        <Text as="h2" ext>
          {number}
        </Text>
      </Box>
      <ColumnText>{children}</ColumnText>
      {smallText && (
        <Box mt={4}>
          <Text styleType="body" ext>
            {smallText}
          </Text>
        </Box>
      )}
    </Column>
  );
};

GreyPanel.propTypes = {
  number: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  smallText: PropTypes.string,
};

GreyPanel.defaultProps = {
  smallText: null,
};

export default GreyPanel;
